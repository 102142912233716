import React from 'react'
import Nav from "../components/Navigation/Nav";

function Profile() {
  return (
    <div>
    <div><Nav /></div>
    <div>Profile</div>
    </div>
  )
}

export default Profile